import './sass/main.scss'
import './sass/delay.scss'
import $ from 'jquery'
import Swiper from 'swiper'
import './lib/swiper'
import lazyload from 'lazyload/lazyload'

$(function () {
  /*== smooth scroll ====================*/
  var headerHeight = $('.not-top-header').outerHeight();
  var urlHash = location.hash;
  if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function(){
      var target = $(urlHash);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({scrollTop:position}, 500);
    }, 100);
  }
  $('a[href^="#"]').click(function() {
    var href= $(this).attr("href");
    var target = $(href);
    var position =  Math.floor(target.offset().top) - headerHeight;
    $('body,html').stop().animate({scrollTop:position}, 500);
  });


  $(window).scroll(function() {
    if ($(this).scrollTop() > 1) {
      $('.top-header').addClass("u-backgroundcolor-white");
      $('.top-header .p-header-content__logo').addClass("p-header-content__logo--black");
      $('.top-header .p-header-content__search').addClass("p-header-content__search--black");
      $('.top-header .p-header-content__hamburger').addClass("p-header-content__hamburger--black");
    } else {
      $('.top-header').removeClass("u-backgroundcolor-white");
      $('.top-header .p-header-content__logo').removeClass("p-header-content__logo--black");
      $('.top-header .p-header-content__search').removeClass("p-header-content__search--black");
      $('.top-header .p-header-content__hamburger').removeClass("p-header-content__hamburger--black");
    }
  });

  $(window).scroll(function() {
    if ($(this).scrollTop() > 1){
      $('.not-top-header').addClass("u-fixed");
    }
    else{
      $('.not-top-header').removeClass("u-fixed");
    }
  });

  $('.p-header-content__search').on('click', function () {
    $('.p-search-sidetab-overlay').css('display', 'block');
    $('.l-search-sidetab').animate({'marginRight':'0px'},400);
    $('body').css('overflow-y', 'hidden');
  });

  $('.c-batsu-wrap--search-tab').on('click', function () {
    $('.p-search-sidetab-overlay').css('display', 'none');
    $('.l-search-sidetab').animate({'marginRight':'-100%'},400);
    $('body').css('overflow-y', 'auto');
  });

  $('.p-header-content__hamburger').on('click', function () {
    $('.l-menu-modal').fadeIn();
    $('body').css('overflow-y', 'hidden');
  });

  $('.c-batsu-wrap--menu-modal').on('click', function () {
    $('.l-menu-modal').fadeOut();
    $('body').css('overflow-y', 'auto');
  });

  let tab = document.getElementsByClassName("p-article-tab");
  let tabs = Array.from(tab);
  let content = document.getElementsByClassName("p-article-content");
  let contents = Array.from(content);

  tabs.forEach(function(showTab, index) {
    showTab.addEventListener('click', function() {
      let hideTab = document.getElementsByClassName("p-article-tab--active")[0]
      let hideContent = document.getElementsByClassName("p-article-content--active")[0];
      let showContent = contents[index];
      hideContent.classList.remove("p-article-content--active");
      hideTab.classList.remove("p-article-tab--active");
      showContent.classList.add("p-article-content--active");
      showTab.classList.add("p-article-tab--active");
    });
  });

  const descriptionSwiper = new Swiper(".p-top-description-swiper", {
    loop: true,
    slidesPerView: 4,
    spaceBetween: 60,
    centeredSlides: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    autoplay: {
      delay: 2000,
    },
    breakpoints: {
      // 768以下なら
      768: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 20,
        freeMode: true,
        speed: 1000,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
      },
    }
  });

  $('.p-search-keyword-wrap').hover(
    function() {
      $(this).css('background-color', 'black')
      $(this).children('.p-search-keyword').css('color', 'white')
    },
    function() {
      $(this).css('background-color', 'white')
      $(this).children('.p-search-keyword').css('color', 'black')
    }
  );

  const categorySwiper = new Swiper(".p-category-swiper", {
    spaceBetween: 35,
    slidesPerView: 8,
    autoplay: {},
    breakpoints: {
      // 1088以下なら
      1088: {
        slidesPerView: 3,
        spaceBetween: 20,
        loop: true,
        initialSlide: 1,
        freeMode: true,
        speed: 2000,
        autoplay: {
          waitForTransition: 2000,
          disableOnInteraction: false,
        },
      },
    }
  });

  $('.c-category-logo-wrap').hover(
    function() {
      $(this).css('background-color', 'black')
      $(this).children('.c-category-logo').css('filter', 'invert(100%)')
    },
    function() {
      $(this).css('background-color', 'white')
      $(this).children('.c-category-logo').css('filter', 'invert(0%)')
    }
  );

  // const featureSwiper = new Swiper(".p-feature-top", {
  //   loop: true,
  //   slidesPerView: 1,
  //   pagination: {
  //     el: ".swiper-pagination",
  //     clickable: true
  //   },
  // });

  $('.c-post-index-link-btn').hover(
    function() {
      $(this).css('background-color', 'black')
      $(this).css('color', 'white')
      $(this).children().css('margin-right', '-15px')
    },
    function() {
      $(this).css('background-color', 'white')
      $(this).css('color', 'black')
      $(this).children().css('margin-right', '15px')
    }
  );

  const professionalSwiper = new Swiper(".p-professional-swiper", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    speed: 10000,
    autoplay: {
      delay: 0,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        disableOnInteraction: false,
        speed: 5000,
      },
    }
  });

  if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
    $('#p-top-description-swiper__operation-prev').on('touchstart', changePrevBtn);
    $('#p-top-description-swiper__operation-prev').on('touchend', returnPrevBtn);
    $('#p-top-description-swiper__operation-next').on('touchstart', changeNextBtn);
    $('#p-top-description-swiper__operation-next').on('touchend', returnNextBtn);
  } else {
    $('#p-top-description-swiper__operation-prev').mouseover(changePrevBtn);
    $('#p-top-description-swiper__operation-prev').mouseout(returnPrevBtn);
    $('#p-top-description-swiper__operation-next').mouseover(changeNextBtn);
    $('#p-top-description-swiper__operation-next').mouseout(returnNextBtn);
  }

  function changePrevBtn() {
    const element = document.getElementById("p-top-description-swiper__operation-prev");
    element.classList.add("swiper-button-black");
    element.classList.remove("swiper-button-white");
    element.style.backgroundColor = 'white';
  }

  function returnPrevBtn() {
    const element = document.getElementById("p-top-description-swiper__operation-prev");
    element.classList.add("swiper-button-white");
    element.classList.remove("swiper-button-black");
    element.style.backgroundColor = 'black';
  }

  function changeNextBtn() {
    const element = document.getElementById("p-top-description-swiper__operation-next");
    element.classList.add("swiper-button-white");
    element.classList.remove("swiper-button-black");
    element.style.backgroundColor = 'black';
  }

  function returnNextBtn() {
    const element = document.getElementById("p-top-description-swiper__operation-next");
    element.classList.add("swiper-button-black");
    element.classList.remove("swiper-button-white");
    element.style.backgroundColor = 'white';
  }

  $('#p-search-form-pulldown__select').on("change",
    function() {
      const pulldownElement = document.getElementById("p-search-form-pulldown__select");
      const textFieldElement = document.getElementById("p-search-form-input");
      textFieldElement.value = pulldownElement.value
      pulldownElement.value = ""
    }
  );
  new lazyload()
  $('.yyi-rinker-images-arrow').on('click', function(element) {
    const parent = $(element.target).parent()
    let images = parent.children('.yyi-rinker-main-img').get()
    let toActiveIndex
    const isLeft = $(element.target).hasClass('yyi-rinker-images-arrow-left')
    if ( isLeft ) {
      images = images.reverse()
    }
    images.forEach(function(element, index) {
      if ( !$(element).hasClass('hidden') ) {
        $(element).addClass('hidden')

        toActiveIndex = index + 1
      }
      if (index === toActiveIndex) {
        $(element).removeClass('hidden')
      }
    })
    if (isLeft &&  toActiveIndex === images.length - 1) {
      parent.children('.yyi-rinker-images-arrow-left').addClass('hidden')
    } else if (toActiveIndex === images.length - 1) {
      parent.children('.yyi-rinker-images-arrow-right').addClass('hidden')
    }

    if (toActiveIndex !== images.length - 1) {
      parent.children('.yyi-rinker-images-arrow-right').removeClass('hidden')
      parent.children('.yyi-rinker-images-arrow-left').removeClass('hidden')
    }
  })
});
