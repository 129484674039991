import Swiper from 'swiper'

$(function () {
  /*== product introduction swiper ====================*/

  /*== initialize thumbnail swiper ==*/
  var productIntroductionThumbsSwiper = []
  var swiperContaineriesThumbs = Array.from(document.querySelectorAll('.product-introduction-slider-thumbnails'));
  swiperContaineriesThumbs.forEach((element, index) => {
    element.classList.add(`product-introduction-thumbnail_${index}`)
    productIntroductionThumbsSwiper.push(new Swiper(`product-introduction-thumbnail_${index}`, {
      slidesPerView: 'auto',
    }))
    element.firstElementChild.classList.toggle('p-swiper-slide-thumb-active')
  })

  /*== initialize main swiper ==*/
  var swiperContainers = Array.from(document.querySelectorAll('.product-introduction-main-swiper'));
  var swiperFuncs = []
  swiperContainers.forEach((element, index) => {
    if (element.childElementCount > 1) {
      element.classList.add(`swiper-container_${index}`)
    }

    swiperFuncs.push(
      new Swiper(`.swiper-container_${index}`, {
        loop: true,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        thumbs: {
          swiper: productIntroductionThumbsSwiper[index]
        },
        on: {
          slideChange: function () {
            if (this.passedParams.thumbs.swiper) {
              var activeThumb = document.getElementsByClassName(this.passedParams.thumbs.swiper.passedParams.el)[0]
              activeThumb.querySelector('.p-swiper-slide-thumb-active').classList.toggle('p-swiper-slide-thumb-active')
              var thumbs = $(`.${this.passedParams.thumbs.swiper.passedParams.el}`)
              thumbs[0].children[this.realIndex].classList.toggle('p-swiper-slide-thumb-active');
            }
          },
        },
      })
    )
  });

  $('.product-introduction-thumbnail').on('click', event => {
    var clickedElement = event.target
    var thumbsParentNode = clickedElement.parentNode
    var currentActiveThumb = thumbsParentNode.querySelector('.p-swiper-slide-thumb-active')
    currentActiveThumb.classList.toggle('p-swiper-slide-thumb-active');
    clickedElement.classList.toggle('p-swiper-slide-thumb-active');

    var productIntroductionThumbs = document.getElementsByClassName('product-introduction-slider-thumbnails')
    var mainSwiperindex = [].slice.call(productIntroductionThumbs).indexOf(thumbsParentNode);
    var thumbnailIndex = Array.from(thumbsParentNode.children).indexOf(clickedElement)
    swiperFuncs[mainSwiperindex].slideToLoop(thumbnailIndex);
  });
  // end function
});

